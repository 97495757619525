<template>
    <div id="competition_list">
        <van-image fit="cover" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210615142739675.png" class="back_cover" />
        <div class="time_box">
            <div class="time_tips">距离活动结束还有</div>
            <van-count-down :time="time">
                <template #default="timeData">
                    <span style="margin-right: 8px" class="block">{{ timeData.days }}天</span>
                    <span class="block">{{ timeData.hours }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                </template>
            </van-count-down>
        </div>
        <div v-for="(item, index) in list" >
          <div :class="item.type == 1 ? 'top_box top_box_jin' : 'top_box'" :style="index == 1 ? 'margin-top:60px' : ''">
            <img class="avatar" :src="item.headImgUrl ? item.headImgUrl : 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210625104311334.png'" alt="">
            <div class="top_words">{{item.realName}}{{!item.isOver ? '当前' : '最终'}}排名</div>
            <div style="margin-top: 5px;color: #fe6161" class="rank_num">
              <span style="font-size: 28px;margin-right: 2px">{{item.listRank}}</span>
              <span>名</span>
            </div>
            <div class="zuizhong" style="display: flex;margin-top: 3px;color: #666666;align-items: center;line-height: 1.0;font-weight: bold">
              <span v-if="showMoney">{{!item.isOver ? '当前' : '最终'}}在{{item.listName}}，订单金额： ¥{{item.performance}}</span>
              <span v-else style="position: relative;top: 3px">*******************</span>
              <img @click="showMoney = !showMoney" style="width: 21px;height: 14px;margin-left: 4px" class="eye" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210622141500019.png" alt="">
            </div>
          </div>
          <div class="style_box" style="display: flex">
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210622145529489.png" alt="">
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210622145529489.png" alt="">
          </div>
          <div class="bottom_box">
            <div class="bottom_title_box" style="display: flex;">
              <div class="title_line"></div>
              <div class="title_words">排行榜奖品</div>
              <div class="title_line"></div>
            </div>
            <div v-for="item2 in item.prize" class="gift_box">
              <div style="font-size: 17px;font-weight: bold">{{item2.prizeName}}</div>
              <van-image fit="contain" class="gift_img" :src="item2.prizeUrl" alt="" />
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import {replaceAppToken} from "../../../utils/appUtil";
    import {toChineseNum} from "../../../utils/handle";

    export default {
        name: "list",
        data() {
            return {
                time: 30 * 60 * 60 * 1000,
                timeData: {},
                showMoney: true,
                list: [],
                isInApp: !!this.$route.query.token,
            }
        },
        created() {
            document.title = '百万英雄争霸赛'
            if (this.isInApp) {
                replaceAppToken(this.$route.query.token, () => {
                    this.Init()
                });
            } else {
                this.Init()
            }
            this.getTime()
        },
        methods: {
            getTime() {
                this.post('/Base/Tools/ModuleIsShowListV2', {
                    moduleKEY: 'gzhMZcomping20210618'
                }).then(data => {
                    this.time = new Date(data.response[0].model.useTimeEnd).getTime() - new Date().getTime()
                })
            },
            Init() {
                this.$toast.loading()
                this.post('/OnlyOnce/HeroCompetitionActivty/GetPersonalProgress', {
                    batch: 2
                }).then(data => {
                    this.$toast.clear()
                    if (data.code) {
                        //---------------------------------------------------
                        const list = data.response
                        this.list = list
                        // console.log(list)
                    } else {
                        //身份不匹配
                        return this.$dialog.alert({
                            title: '提示',
                            message: data.msg
                        })
                            .then(() => {
                                this.$router.push('/')
                            })
                    }
                    // console.log(data)
                    // if (!!data.code) {
                    //   this.list = data.response
                    //   console.log(this.list)
                    // }
                })
            }
        }
    }
</script>

<style lang="scss">
    #competition_list {
      padding-bottom: 40px;
        .colon {
            display: inline-block;
            margin: 0 2px;
            color: #fff;
        }
        .block {
            display: inline-block;
            min-width: 26px;
            box-sizing: border-box;
            padding-left: 4px;
            padding-right: 4px;
            /*margin-right: 5px;*/
            color: #fff;
            font-size: 12px;
            text-align: center;
            background-color: #221c4c;
            box-shadow: 0 0 10px #494e87;
            border-radius: 8px;
        }
        .back_cover {
            position: fixed;
            z-index: -1;
            top: -41px;
            left: 0;
            width: 100%;
        }

        .time_box {
            position: fixed;
            z-index: 99;
            top: 5px;
            right: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .time_tips {
            color: #fff;
            font-size: 14px;
            text-shadow: 0 0 10px #221c4c;
            margin-bottom: 2px;
        }
        .top_box{
            width: 90%;
            height: 155px;
            background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210622115357363.png");
            background-size: 100%;
            position: relative;
            background-repeat: no-repeat;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            .avatar{
                width: 45px;
                height: 45px;
                border-radius: 50%;
                border: 2px solid #b1b7c3;
                margin-top: 15px;
            }
            .top_words{
                color: #fb6451;
                margin-top: 2px;
                font-weight: bold;
                font-size: 13px;
            }
        }
        .top_box_jin{
            background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210624100439513.png");
        }
        .style_box{
            justify-content: space-between;
            margin-left: 50px;
            margin-right: 50px;
            margin-top: -13px;
            z-index: 1;
            position: relative;
            img{
                height: 45px;
            }
        }
        .bottom_box{
            width: 86%;
            border-radius: 10px;
            /*height: 155px;*/
            padding-bottom: 20px;
            background-color: #fff;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-top: -10px;
            overflow: hidden;
            .gift_box{
                margin-top: 15px;
                background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210622161057253.png");
                height: 80px;
                margin-left: 20px;
                margin-right: 20px;
                display: flex;
                align-items: center;
                background-size: 100% 100%;
                box-sizing: border-box;
                padding-left: 20px;
                padding-right: 15px;
                background-repeat: no-repeat;
                justify-content: space-between;
                .gift_img{
                    width: 158px;
                }
            }
        }
        .bottom_title_box{
            margin-top: 20px;
            color: #fb6451;
            font-weight: bold;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            .title_words{
                margin-left: 5px;
                margin-right: 5px;
            }
            .title_line{
                width: 30px;
                height: 1px;
                background-color: #fb6451;
            }
        }
    }
</style>